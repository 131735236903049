import React from 'react';
import { Redirect } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import styles from './Landing.module.css';
import Signup from './Signup/Signup';

import '@firebase/auth';
import '@firebase/database';
import google from '../../Assets/Images/google.png';
import facebook from '../../Assets/Images/facebook.png';
import logo from '../../Assets/Images/logo.png';
import logo_large from '../../Assets/Images/logo_large.png';
import axios from 'axios';
import { Link } from "react-router-dom";

/*
dss

*/
const fields = [
	{
		title: 'username',
		label: 'Username',
		type: ''
	},
	{
		title: 'password',
		label: 'Password',
		type: 'password'
	},
	{
		title: 'accesscode',
		label: 'Access Code',
		type: 'password'
	},
]

const methods = [
	{
		title: 'google',
		label: 'Google',
		img: google,
	},
	{
		title: 'facebook',
		label: 'Facebook',
		img: facebook
	},
]

class Landing extends React.Component {
	
	constructor(props) {
		super(props);

		this.state = {
			username: '',
			password: '',
			email: '',
			user_status: '',
			accesscode: '',
			open: true, 
			inccorect_pass : false,
			account_message: null,
			error: ' ',
			signup: false,
			success: false,
			loading: false,
			ForgotPass: false,
		};
	}
	
	keypress(event) {
		if (event.keyCode === 13 && this.state.signup === false) {
			this.forgot()
		}
		if (event.keyCode === 27) {
			this.setState({ signup: false })
		}
	};

	
	login() {
		console.log("HEY I AM HERE");
		const username = this.state.username
		const password = this.state.password
		const accesscode = this.state.accesscode
		if ([username, password, accesscode].includes('')) {
			this.setState({ error: 'Please Enter All Information' })
		}
		else if(accesscode !== 'Testing22'){
			this.setState({ error: 'Please Enter A Valid Access Code'})
			this.setState({ accesscode: '' })
		}
		else if (username.length < 6) {
			this.setState({ error: 'Username must be 6 characters'})
			//if username is invalid
		} 

		else if(password.length > 0 && password.length <= 6){
			this.setState({ error: 'Password Enter a valid password'})
		}

		else {
			const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			const key = regex.test(String(username).toLowerCase()) ? 'email' : 'username'
			const body = {
				[key]: username,
				password: password
			}
			
			axios.post('/auth/login', body).then(res => {
				
				if (res.status === 200) {
					this.setState({ success: true })	;
					this.setState({inccorect_pass : false})
					// document.cookie = `username=${username}; path=/`;
				}

			//Incorrect username or password.
				
			}).catch(err => {
				const res = err.response;
				console.log(res.data.status);
				if(res.data.status === "Incorrect username or password."){
					this.setState({ error: 'Password Incorrect'});
					this.setState({ success: false })
					this.setState({inccorect_pass: true});
				}
				if(res.data.status === "User not found."){
					console.log("HHERE");
					this.setState({ error: 'Username Incorrect'});
					this.setState({ success: false })
					this.setState({inccorect_pass: true});
				}
				if (res.status === 409) {
				
					this.setState({ error: res.data.status })
					//this.setState({accesscode: ' '})
				} 
				// else {
				// 	if (accesscode.length < 6) {
				// 		this.setState({ error: 'please enter valid code'})
				// 		//this.setState({ accesscode: '' })
				// 	}
				// 	else {
				// 		this.setState({ error: 'Username is incorrect'})
				// 		//this.setState({ username: '' })
				// 	}
				// }
				// 	if(password.length > 0 && password.length <= 6) {
				// 		this.setState({ error: 'Password must be longer than 6 characters'})
				// 	}
				// 	else {
				// 		this.setState({ error: 'Passwords is Incorrect' })
				// 		this.setState({ password: '' })
				// 	  }
				
			
			})
		}
	}


	/*signInWith(method) {
		  axios.get(`/auth/${method}`).then(res => {
			  console.log(res)
			if(res.status === 200) this.setState({ success: true })
		  })
	}*/

	componentDidMount() {
		document.addEventListener("keydown", (e) => this.keypress(e), false);
		this.setState({ open: true});
		const body = {
			email: 'e',
			password: 'e'
		}
		//console.log("DID MOUNT");
		// axios.post('/auth/login', body).then(res => {
		// 	console.log(res);
		// }).catch(err => {
		// 	const res = err.response
		// 	if (res.status === 409) {
		// 		this.setState({ success: false })
		// 		axios.get('/auth/logout')
		// 	}
		// 	if (res.status === 404) return
		// })
	}

	clearCookies() {
		document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
	}

	render() {
		// const propState = this.props.location.state
		// if(propState) {
		// 	console.log(propState)
		// 	if(propState.logout) {
		// 		this.clearCookies()
		// 	}
		// }
		// const cookieUser = decodeURIComponent(document.cookie).split('username=')[1]
		// console.log(cookieUser)

		// console.log(document.cookie)

		/**
		 * When a user successfully logs in, the state variable "success" is set to true
		 * This results in a rerender, which happens every time state changes
		 * The axios call inside the if statement is then called which retrieves user email and user_status, 
		 * and sets the state with those values.
		 */
		if (this.state.success) {
			axios.get(`/api/user/${this.state.username}`).then(result => {
				const resData = result.data.status
				this.setState({ email: resData['email'], user_status: resData['user_status'], account_message: resData['account_message'] })
			}).catch(err => { })
		}
		/**
		 * Since setState is a queue and isn't automatic, we have to make sure the user's user_status and email are updated.
		 * Once validated, we can create an object that holds this state data, and passes it as a prop into the homepage.
		 */
		if (this.state.user_status !== ''){
			const re = {
				pathname: '/home',
				state: {
					username: this.state.username,
					email: this.state.email,
					user_status: this.state.user_status,
					account_message: this.state.account_message,
				}
			}
			return <Redirect to={re} />
		}

		// firebase.auth().getRedirectResult().then((result) => {
		//   const user = result.user;
		//   if(user) {
		//   	this.setState({ success: true })
		//   }
		// })


		/**
		 * <div>
      				  {open && (
         				 <div class={styles.popup}>
        		 		   <div class={styles.popupContent}>
            			 	 <h1>Welcome to NutraData </h1>
             				 <p>We're thrilled to have you join us on your health and wellness journey.</p>
							 <p> As a company that specializes in tracking supplements, we're here to provide you with accurate and up-to-date information to help you make informed decisions about the supplements you use. With our user-friendly platform and comprehensive database, you can easily find the information you need to optimize your health and achieve your wellness goals. Let us be your trusted source for all things supplement-related, and together, we'll navigate the world of nutrition and supplements with confidence.</p>
             				 <button class = {styles.button} onClick={() => this.setState({ open: false })}>
             					   View the Website
            				  </button>
           				 </div>
         			 </div>
        				)}
      				</div>
		 */
		const open  = this.state.open;
		return (
			
			<div class={styles.landing}>
				<div class={styles.left}>
				
					<img
						src={logo_large}
						alt='logo'
						style={{ height: '100vh' }}
					/>
				</div>
				<div class={styles.right}>
					<div class={styles.content}>
						<div class={styles.title}>
							<img
								src={logo}
								alt='logo'
								style={{ display: 'inline', height: '40px', verticalAlign: 'middle' }}
							/>
							<div class={styles.titleText}>NutraData™<p class = {styles.para}> Your go-to website for tracking supplements</p></div>
						</div>
						{fields.map(({ title, label, type }) => {
							return (
								<div class={styles.input}>
									<TextField
										value={this.state[title]}
										onChange={(e) => this.setState({ [title]: e.target.value })}
										id='standard'
										label={label}
										type={type}
										width={1}
										fullWidth='true'
									/>
								</div>
							)
						})}
						<div class={styles.error}>
							{this.state.error}
						</div>
						<div
							class={styles.login}
							onClick={() => {
								if(this.state.accesscode === 'Testing22'){
									this.login();
								} 
								
								if (this.state.accesscode !== 'Testing22'){
									this.setState({ error: 'Please Enter A Valid Access Code'});
								}
							}}
							
							//onClick={() => this.state.username.length <  6 ? this.login() : this.setState({ error: 'Please Enter A Valid username'})}
							//onClick={() => this.state.password.length <  6 ? this.login() : this.setState({ error: 'Please Enter A Valid password'})}
							//class={styles.login}
						
						>
							Log in
							
						</div>
						
						<div
							class={styles.signup}
							onClick={() => this.setState({ signup: true, error: '' })}
						>
							Sign up
						</div>

						<div
						//class={styles.forgot} 
						//onClick={() => this.setState({ ForgotPass: true, error: '' })}
						>

						</div>
						<div id={styles.forgot}>
							<a href="/login/reset">Forgot password/username?</a>
						</div>

					</div>
				</div>
				{this.state.signup ? <Signup open={this.state.signup} /> : null}


			</div>
		)
	}
}

export default Landing;

