import React from 'react';
import styles from './List.module.css';
import { Button, Select, InputLabel, MenuItem } from '@material-ui/core';
import CalculateIcon from '@mui/icons-material/Calculate';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import HelpIcon from '@material-ui/icons/Help';
import axios from 'axios';
import Checkbox from '@material-ui/core/Checkbox';
import PrintIcon from '@material-ui/icons/Print';
// import Box from '@material-ui/core/Box';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ReactToPrint from "react-to-print";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TabPanel from './TabPanel';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Image from '../Add/Types/Image';
import Search from '../Add/Types/Search';
import UPC from '../Add/Types/UPC';
import DeleteIcon from '@mui/icons-material/Delete';


/* npm install --save react-to-print */

/**
 * Nutrient table containing ALL nutrients 
 */
class NutrientTable extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			collapsed: true,
		};
	}

	/**
	 * Generates the css styling for a supplement information row, based on the row index (ri)
	 * @param {number} ri : row number
	 * @returns style for a row
	 */
	rowStyle(ri) {
		if (ri % 2 === 1) {
			return styles.rowWhite
		}
		else {
			return styles.rowGray
		}
	}

	render() {
		const agg = this.props.agg
		const collapsed = this.state.collapsed
		return (
			<div class={styles.nutrientTable}>
				{/* Header information for nutrient table */}
				<div class={styles.nutrientHeader}>
					<div class={styles.titleAndCollapse}>
						<div class={styles.completeNutrientList}>
							<em>{"Complete Nutrient List"}</em>
						</div>
						<div class={styles.collapse} onClick={() => this.setState({ collapsed: !collapsed })}>
							{this.state.collapsed ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
						</div>
					</div>
					<div>

						<div class={styles.nutrientNameHeader}>
							{"Nutrient"}
						</div>
						<div class={styles.nutrientAmountHeader}>
							{"Amount"}
						</div>
					</div>
				</div>
				<div class={styles.leftScrollableDiv}>
					{/* Where ALL nutrients will be mapped */}
					{(!!agg && !collapsed) ? Object.keys(agg).map((item, ri) => {
						return (
							<div class={this.rowStyle(ri)}>
								<div class={styles.nutrientName}>
									{item}
								</div>

								<div class={styles.nutrientAmount}>
									<div class={styles.nutrientParent}>
										<div class={styles.nutrientChild}>
											{Array.isArray(agg[item].amount) ? agg[item].amount.map(element => <div>{element}</div>) : null}
										</div>
										<div class={styles.nutrientChild}>
											{Array.isArray(agg[item].unit) ? agg[item].unit.map(element => <div>{element}</div>) : null}
										</div>
										{Array.isArray(agg[item].unit) ? agg[item].unit.length > 1 ? <div class={styles.note}>~ See Above ~</div> : null : null}
									</div>
								</div>
							</div>
						)
					}) : null
					}
				</div>
			</div>
		)
	}
}

/**
 * Other Ingredient table containing other ingredients and the supplements that contain them
 */
class OtherIngredientTable extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			collapsed: true,
		};
	}


	/**
	  Generates the css styling for a supplement information row, based on the row index (ri)
	 * @param {number} ri : row number
	 * @returns style for a row
	 */
	rowStyle(ri) {
		if (ri % 2 === 1) {
			return styles.otherRowWhite
		}
		else {
			return styles.otherRowGray
		}
	}


	render() {
		const otherIngredients = this.props.otherIngredients
		const collapsed = this.state.collapsed

		return (
			<div class={styles.nutrientTable}>
				{/* Header information for nutrient table */}
				<div class={styles.nutrientHeader}>
					<div class={styles.titleAndCollapse}>
						<div class={styles.completeNutrientList}>
							<em>{"Other Ingredients List"}</em>
						</div>
						<div class={styles.collapse} onClick={() => this.setState({ collapsed: !collapsed })}>
							{this.state.collapsed ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
						</div>
					</div>
					<div>
						<div class={styles.otherIngredientNameHeader}>
							{"Ingredient"}
						</div>
						<div class={styles.otherIngredientProductHeader}>
							{"Products Containing Ingredient"}
						</div>
					</div>
				</div>
				<div class={styles.leftScrollableDiv}>
					{/* Where ALL nutrients will be mapped */}
					{(!!otherIngredients && !collapsed) ? Object.keys(otherIngredients).map((item, ri) => {
						return (
							<div class={this.rowStyle(ri)}>
								<div class={styles.otherIngredientNames}>
									{item}
								</div>
								<div class={styles.otherIngredientProducts}>
									{
										Object.keys(otherIngredients[item]).map((index) => {
											return <div>
												{otherIngredients[item][index]}
											</div>
										})
									}
								</div>
							</div>
						)
					}) : null
					}
				</div>
			</div>
		)
	}
}

class List extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			events: null,
			panel: false,
			warning: null,
			clicked: false,
			aggregate: null,
			multipleUnits: false,
			lists: null,
			event: 0,
			eventID: null,
			eventValue: null,
			inventory: [],
			error: null,
			rdaAggregate: null,
			rdaCategory: "",
			otherIngredients: null,
			NANError: false,
			InvalidNumberError: false,
			rdaCollapsed: true,
			collapsed: false,
			print: false,
			value: 0, //value for which add tab is open
			display: false	// display of the add popup
		};
	}

	rowStyle(ri) {
		if (ri % 2 === 1) return styles.rowWhite
		else return styles.rowGray
	}

	keypress(e) {
		if (e.keyCode === 27) this.exit()
	}

	exit() {
		this.setState({ event: 0 })
		this.exitAdd() //close the inner popup too
		this.getHistory()
	}

	/* This retrieves the items that are listed when you click add supplement */
	getInventory() {
		axios.get(`/api/user/${this.props.username}/homeInventory`).then(res => {
			this.setState({ inventory: res.data.status })
		}).catch(err => {
			console.log('No inventory')
		})
	}

	/* This retrieves the items that are in the left hand table and when you click remove supplement */
	getHistory() {
		axios.get(`/api/user/${this.props.username}/history`).then(res => {
			if (res.status === 200) {
				console.log(res)
				this.getRDACategory()
				this.setState({ events: res.data.status.events })
				this.setState({ aggregate: res.data.status.aggregated })
				this.setState({ rdaAggregate: res.data.status.rdaAggregated })
				//turning javascript object back into a map
				this.setState({ otherIngredients: res.data.status.otherIngredients })
			}
		}).catch(err => {
			console.log('No products have been added')
		})
	}

	/* Determines what RDA category will be used for the user */
	getRDACategory() {
		axios.get(`/api/user/${this.props.username}/rdaCategory`).then(res => {
			if (res.status === 200) {
				this.setState({ rdaCategory: res.data.status.category })
			}
		}).catch(err => {
			console.log('Error retrieving RDA category')
		})
	}

	/* When you click add (within add supplement) */
	record() {
		const id = this.state.eventID;
		const username = this.props.username;
		console.log("hello " + id +  " " +username);
		if (!id) this.setState({ error: 'Please select a product to add.' })
		else {
			const body = {
				dsld_id: id,
				username: username,
				amount: 0,    //By default the amounts for all stacks will be zero, so we don't even bother passing it in
			}

			axios.post('/api/event', body).then(res => {
				if (res.status === 200) {
					this.setState({ error: null })
					this.exit()
					this.getInventory() //refreshes inventory for the add supplement button after an supplement has been added
				}
			}).catch(err => {
				this.setState({ error: err.response.data.status })
			})
		}
	}

	/* When you click remove (within remove supplement) */
	// remove() {
	// 	const id = this.state.eventID
	// 	if (!id) this.setState({ error: 'Please select a product to remove' })
	// 	else {
	// 		const body = {
	// 			event_id: id,
	// 		}
	// 		axios.delete('/api/event/' + id, body).then(res => {
	// 			if (res.status === 200) {
	// 				this.setState({ error: null })
	// 				this.exit()
	// 				this.getInventory() //refreshes inventory for the add supplement button
	// 				this.getHistory()   //refreshes products listed in the table
	// 			}
	// 		}).catch(err => {
	// 			this.setState({ error: err.response.data.status })
	// 		})
	// 	}
	// }

	removeProduct(eventID) {
		// const id = this.state.eventID
		if (!eventID) this.setState({ error: 'Please select a product to remove' })
		else {
			const body = {
				event_id: eventID,
			}
			axios.delete('/api/event/' + eventID, body).then(res => {
				if (res.status === 200) {
					this.setState({ error: null })
					this.exit()
					this.getInventory() //refreshes inventory for the add supplement button
					this.getHistory()   //refreshes products listed in the table
				}
			}).catch(err => {
				this.setState({ error: err.response.data.status })
			})
		}
	}

	componentDidMount() {
		document.addEventListener("keydown", (e) => this.keypress(e), false)
		this.getInventory()
		this.getHistory()
	}

	//This will check all nutrient list ingredients for multiple units
	componentDidUpdate(prevProps, prevState) {
		if(prevState.aggregate !== this.state.aggregate) {
			
			let aggArray = Object.values(this.state.aggregate)

			for (let index = 0; index < aggArray.length; index++) {
				if(aggArray[index].unit.length > 1) {
					this.setState({ multipleUnits: true })
					return
				}
			}

			this.setState({ multipleUnits: false })
		}

		if(prevState.rdaAggregate !== this.state.rdaAggregate) {

			let rdaAggArray = Object.values(this.state.rdaAggregate)

			for (let index = 0; index < rdaAggArray.length; index++) {
				if(rdaAggArray[index].unit.length > 1) {
					this.setState({ multipleUnits: true })
					return
				}
			}

			this.setState({ multipleUnits: false })

		}
	}

	/* Code for after clicking buttons at top of the page
	   	e = 1 when you click Add Supplement
   		e = 2 when you click Remove Supplement 
   		e = 3 when you click the Tutorial button
   		e = 4 when you click the Fraction/Decimal Conversion button
	*/	  

	handleTabChange = (e, newValue) => {
		this.setState({ value: newValue })
	}

	openType(type) {
		this.setState({ display: type })
		console.log(type);
	}

	exitAdd() {
		this.setState({ display: false })
	}
	
	recordChoice(e) {
		const addTypes = {
			'Scan Barcode': {
				component: <Image username={this.props.username} value = {1} />
			},
			'Search By UPC': { 
				component: <UPC username={this.props.username} value = {1}/>
			},
			'Search By Name': { 
				component: <Search username={this.props.username}/>
			},
		}

		if (e === 1) {
			return (
				<div class={styles.backdrop}>
					<div class={styles.popup}>

						<Box sx={{padding: 5}}>
							<div sx={{display: 'flex', justifyContent: 'center'}}>
								<Tabs 
									value={this.state.value}
									onChange={this.handleTabChange}
								>
									<Tab label="Find Your Supplement" />
									
									<Tab label="Select from Inventory" />
								</Tabs>
							</div>
							<TabPanel value={this.state.value} index={0}>
								<div>
									<div class={styles.popupDescription}>
										<em>Adds to <b>My Supplements</b> and <b>Inventory</b></em>
									</div>

									<Stack direction="row" spacing={3} className={styles.btnRow}>
										{Object.keys(addTypes).map((type, index)  => {
											return(
												<Button
													key={type} 
													variant="contained"
													onClick={() => this.openType(type)}>
														{type}
												</Button>
											)
										})}
									</Stack>

									<div class={styles.cancel} onClick={() => this.exit()}>
											Cancel
									</div>

									{this.state.display ? 
										<div class={styles.backdrop}>
											<div class={styles.addPopup}>
												{addTypes[this.state.display].component}
												<div style={{margin: '20px'}}>
													<Button margin="50%" variant="contained" size="medium" onClick={() => { this.exitAdd() }}>
														Exit
													</Button>
												</div>
											</div>
										</div> 
									: null}

								</div>
								
								
							</TabPanel>
							<TabPanel value={this.state.value} index={1}>
								<div>
									<div>
										<div class={styles.popupDescription}>
											<em>Move from <b>Inventory</b> to <b>My Supplements</b></em>
										</div>
										<InputLabel>
											Product
										</InputLabel>
										<Select
											value={this.state.eventID}
											onChange={(e) => this.setState({ eventID: e.target.value })}
											width={1}
											fullWidth={true}
										>
											{this.state.inventory?.map(product => {
												return <MenuItem value={product.dsld_id}>{product.product_name}</MenuItem>
											})}
										</Select>
									</div>
									<div class={this.state.error ? styles.error : styles.none}>
										{this.state.error}
									</div>
									<Box className={styles.removeCancel} display="flex" justifyContent="space-evenly">
										<div class={styles.record} onClick={() => this.record()}>
											Move
										</div>
										<div class={styles.cancel} onClick={() => this.exit()}>
											Cancel
										</div>
									</Box>
								</div>
							</TabPanel>
						</Box>
					
					</div>
				</div>
			)
		}
		if (e === 2) {
			return (
				<div class={styles.backdrop}>
					<div class={styles.popup}>
						<div class={styles.cs}>
							<div class={styles.invField}>
								<div class={styles.popupDescription}>
									<em>Remove from <b>My Supplements</b></em>
								</div>
								<InputLabel>Product</InputLabel>
								<Select
									value={this.state.eventID}
									onChange={(e) => this.setState({ eventID: e.target.value })}
									width={1}
									fullWidth={true}>
									{this.state.events?.map(product => {
										return <MenuItem value={product.event_id}>{product.product_name}</MenuItem>
									})}
								</Select>
							</div>
							<div class={this.state.error ? styles.error : styles.none}>
								{this.state.error}
							</div>
							<Box className={styles.removeCancel} display="flex" justifyContent="space-evenly">
								<div class={styles.record} onClick={() => this.remove()}>
									Remove
								</div>
								<div class={styles.cancel} onClick={() => this.exit()}>
									Cancel
								</div>
							</Box>
						</div>
					</div>
				</div>
			)
		}
		if (e === 3) {
			return (
				<div class={styles.youtubeBackdrop} onClick={() => this.exit()}>
					<div class={styles.video}>
						<iframe width="711.11" height="400" src="https://www.youtube.com/embed/XZUKLUghx34" frameborder="0" allowFullScreen>
						</iframe>
					</div>
				</div>
			)
		}
		if (e === 4) {
			return (
				<div class={styles.backdrop} onClick={() => this.exit()}>
					<div class={styles.conversionPopup}>
						<div class={styles.conversions}>
							1/4 → .25
						</div>
						<div class={styles.conversions}>
							1/3 → .33
						</div>
						<div class={styles.conversions}>
							1/2 → .50
						</div>
						<div class={styles.conversions}>
							2/3 → .66
						</div>
						<div class={styles.conversions}>
							3/4 → .75
						</div>
					</div>
				</div>
			)
		}
	}

	/* 
	 * Update the value of a stack
	 */
	updateStackValue(newValue, ev_id, stackType, stackId) {
		if (newValue === "") {
			var lastIndex = stackId.lastIndexOf(" ")
			document.getElementById(stackId).innerHTML = stackId.substring(lastIndex + 1)
			return
		}
		else if (Number.isNaN(newValue)) {
			var lastIndex = stackId.lastIndexOf(" ")
			document.getElementById(stackId).innerHTML = stackId.substring(lastIndex + 1)
			this.setState({ NANError: true })
			return
		}
		else if (newValue <= 100 && newValue >= 0) { //Stack value must be between 0 and 9 inclusive
			const body = {
				[stackType]: newValue  //stackType is either breakfast, lunch, or dinner
			}
			axios.put('/api/event/' + ev_id + '/' + stackType, body).then(res => {
				if (res.status === 200) {
					this.getHistory()
				}
			}).catch(err => {
				this.setState({ error: err.response.data.status })
			})
		}
		else {
			var lastIndex = stackId.lastIndexOf(" ")
			document.getElementById(stackId).innerHTML = stackId.substring(lastIndex + 1)
			this.setState({ InvalidNumberError: true })
			return
		}
	}

	/*
	 * Parses a string to an int for stack value updates
	 */
	stringToInt(value) {
		let trimmed = value.trim() //Remove whitespace
		if (trimmed == "") {
			return ""
		}
		else {
			return parseFloat(value, 10)
		}
	}

	/* 
	 *	When checkboxes are clicked
	   */
	handleCheckboxChange(ev_id, checkboxValue) {
		/* Add product's nutrients to nutrient table */
		if (checkboxValue) {
			axios.put(`/api/user/${this.props.username}/` + ev_id + '/checked').then(res => {
				if (res.status === 200) {
					this.getHistory()
				}
			}).catch(err => {
			})
		}
		/* Remove product's nutrients from nutrient table */
		else {
			axios.put(`/api/user/${this.props.username}/` + ev_id + '/unchecked').then(res => {
				if (res.status === 200) {
					this.getHistory()
				}
			}).catch(err => {
			})
		}
	}


	/* Removes (s) from serving type so it's cleaner */
	parseServingType(servingSize, servingType) {
		//Temporary check to avoid errors. Will remove once database has serving info for all products
		if (servingSize == null) {
			return ''
		}
		if (servingSize == 1) {
			return servingType.replace('(s)', '')
		}
		return (servingType.replace('(s)', '') + 's')
	}

	/* Removes () from serving type so it's cleaner */
	removeParentheses(servingType) {
		//Temporary check to avoid errors. Will remove once database has serving info for all products
		if (servingType == null) {
			return ''
		}
		return (servingType.replace('(s)', '') + 's')
	}

	/* Calculates the number of servings based on stack amounts */
	calculateServings(breakfast, lunch, dinner, servingSize) {
		let numPills = breakfast + lunch + dinner
		let sizeFloat = parseFloat(servingSize, 10)
		let size = numPills / sizeFloat
		let str = size.toString()
		if (str.includes(".")) {
			return size.toFixed(2)
		}
		return size
	}

	/* Adds .0 to stack values that are whole numbers */
	addDecimal(value) {
		let str = value.toString()
		if (str.includes(".")) {
			return value.toFixed(2)
		}
		return value.toFixed(1)
	}

	keyDown(e, stackId) {
		if (e.key == "Enter") {
			document.getElementById(stackId).blur()
		}
		else if (e.key == ".") {
			let temp = document.getElementById(stackId).innerHTML
			let counter = 0;
			for (let i = 0; i < temp.length; i++) {
				if (temp.charAt(i) == '.') {
					counter += 1;
				}
			}
			if (counter >= 1) {
				document.getElementById(stackId).blur()
			}
		}
	}

	render() {
		const events = this.state.events
		const e = this.state.event
		const buttons = ['Add', 'Remove']
		const agg = this.state.aggregate
		const listAttributes = this.state.listAttributes
		const rdaAgg = this.state.rdaAggregate
		const rdaCat = this.state.rdaCategory
		const otherIngredients = this.state.otherIngredients
		const rdaCollapsed = this.state.rdaCollapsed
		const collapsed = this.state.collapsed
		const multipleUnits = this.state.multipleUnits

		for(const element in rdaAgg) {
			if(rdaAgg[element].similar[0] === 0 && rdaAgg[element].unit[1] === 'mcg'){
				rdaAgg[element].unit.pop()
				rdaAgg[element].similar[0] = rdaAgg[element].similar[0] + (rdaAgg[element].similar[1] / 1000)
				rdaAgg[element].similar.pop()
			}
		}
		for(const element in rdaAgg) {
			if(rdaAgg[element].similar[0] === 0 && rdaAgg[element].unit[1] === 'mg'){
				rdaAgg[element].unit.pop()
				rdaAgg[element].similar[0] = rdaAgg[element].similar[0] + (rdaAgg[element].similar[1] * 1000)
				rdaAgg[element].similar.pop()
			}
		}

		//Snackbar errors setup
		const handleNANClose = () => {
			this.setState({ NANError: false })
			return
		}
		const handleInvalidNumberClose = () => {
			this.setState({ InvalidNumberError: false })
			return
		}
		const Alert = React.forwardRef(function Alert(props, ref) {
			return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
		});

		return (
			<div>
				<div class={styles.list}>

					{/*Add supplement and remove supplement buttons*/}
					<div class={styles.dailyDosageAndButtons}>
						{/* {buttons.map((button, i) => {
							return (
								<div class={styles.buttons}>
									<Button variant="contained" onClick={() => this.setState({ event: i + 1 })}>
										{button} Supplement
									</Button>
								</div>
							)
						})} */}
						<div class={styles.buttons}>
							<Button variant="contained" onClick={() => this.setState({ event: 1 })}>
								Add Supplement
							</Button>
						</div>
						<div class={styles.dailyDosage}>
							Daily Dosages for <em>{this.props.username}</em>
						</div>
					</div>

					{/* Tutorial button */}
					
					<div class={styles.tutorialButton}>
						<IconButton onClick={() => this.setState({ event: 3 })}>
							<OndemandVideoIcon> 
							</OndemandVideoIcon>
						</IconButton>
					</div>
					<div class={styles.tutorialDescription}>
						Video Tutorial
					</div>

					{/* Fraction/Decimal conversion information button */}
					<div class={styles.fractionDecimalButton}>
						<IconButton onClick={() => this.setState({ event: 4 })}>
							<CalculateIcon>
							</CalculateIcon>
						</IconButton>
					</div>
					<div class={styles.conversionDescription}>
						Fraction to decimal conversions
					</div>
					
					
					{/*Uses the react-to-print module to create an easy button to print nuterient info*/}
					<ReactToPrint
						trigger={() => {
							return (
								<div class={styles.print}>
								<div class={styles.dropdown}> 
								<IconButton onClick={() => this.setState({ print: true})}>
										<PrintIcon>
											
										</PrintIcon>
									</IconButton>

                                    { this.state.print? <div> 
                                    <div class={styles.dropdownc }> 
                                    {/* <div class={styles.print} onClick={() => this.print({ print: this.componentRef})}> 
									Complete Nutrient List
										</div>  */}
									
									 <div onClick={() => this.setState({ print: true})}>
										 <ReactToPrint 
										 	 trigger={() => {
												  return (
													<div> Complete Nutrient List</div>
												  )

											}}
											content={() => this.componentRef}	
										 />
									 </div>

									 <div onClick={() => this.setState({ print: true})}>
										 <ReactToPrint 
										 	 trigger={() => {
												  return (
													<div> Other Ingredients</div>
												  )

											}}
											content={() => this.componentRef2}		
										 />
									 </div>
                          
									 <div onClick={() => this.setState({ print: true})}>
										 <ReactToPrint 
										 	 trigger={() => {
												  return (
													<div> Recommended Daily Allowances</div>
												  )

											}}
											content={() => this.componentRef4}		
										 />
									 </div>

									 <div onClick={() => this.setState({ print: true})}>
										 <ReactToPrint 
										 	 trigger={() => {
												  return (
													<div>Product List</div>
												  )

											}}
											content={() => this.componentRef3}		
										 />
									 </div>

                                    </div>
									</div>:null}
                                </div>
								
							</div> 

							)
						}}

						//content={() => this.componentRef}	
					/>
					
			
					{/* The main product table on the top of page */}
					<div class={styles.productTable} ref={el => (this.componentRef3 = el)} >

						<div class={styles.productHeader}>
							<div class={styles.stackTitle}>
								<em>{"My Supplements"}</em>
							</div>
							<br />
							<div class={styles.headerCheckbox}>
								{"Included"}
							</div>
							<div class={styles.headerNameCell}>
								<div>{"Product Name"}</div>
								<div class={styles.message}><div class={styles.asterisk}>*</div> {" Indicates there are older versions of the product that NutraData™ does not store."}</div>
							</div>
							<div class={styles.headerServingSize}>
								{"Serving Size"}
							</div>
							<div class={styles.portionOfServingTwoRows}>
								<div class={styles.portionOfServing}>
									{"Portion of Serving"}
								</div>
								<div class={styles.headerBreakfastCell}>
									{"Breakfast"}
								</div>
								<div class={styles.headerLunchCell}>
									{"Lunch"}
								</div>
								<div class={styles.headerDinnerCell}>
									{"Dinner"}
								</div>
							</div>
							<div class={styles.headerTotalCell}>
								{"Total Servings"}
							</div>
							<div class={styles.topCollapse} onClick={() => this.setState({ collapsed: !collapsed })}>
								{this.state.collapsed ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
							</div>

						</div>

						<div class={styles.topScrollableDiv}>
							{(!!events && !collapsed) ? events.map((product, ri) => {
								return (
									<div class={this.rowStyle(ri)}>
										<div className={styles.checkboxClass}>
											<Checkbox
												checked={product.checked}
												onChange={(e) => this.handleCheckboxChange(product.event_id, e.currentTarget.checked)}
												color="info"
											/>
										</div>
										<div class={styles.cellName}>
											<div class={styles.productImage} onClick={() => {product.image_link? window.open(product.image_link): window.open("https://s3-us-gov-west-1.amazonaws.com/cg-355fa78b-864d-4a44-b287-0369f11f024a/pdf/"+product.dsld_id+".pdf") }}>{product.product_name}</div> {product.duplicate_upc? <div class={styles.asterisk}>*</div> : null}
										</div>
										<div class={styles.cellServingSize}>
											{`${product.serving_size} ${this.parseServingType(product.serving_size, product.serving_type)}`}
										</div>
										<div class={styles.cell}>
											<div class={styles.allThreeStacks}>
												{/* Breakfast */}
												<div class={styles.stack}>
													<div
														class={styles.plusMinus}
														id={product.breakfast}
														onClick={(e) => this.updateStackValue((this.stringToInt(e.currentTarget.id) + 1), product.event_id, 'breakfast')}
													>
														+
													</div>
													<label
														class={styles.stackValues}
														contentEditable
														id={product.product_name + " breakfast " + product.breakfast}
														tabindex="0"
														onKeyDown={(e) => this.keyDown(e, e.currentTarget.id)}
														onBlur={(e) => this.updateStackValue((this.stringToInt(e.currentTarget.textContent)), product.event_id, 'breakfast', e.currentTarget.id)}
													>
														{this.addDecimal(product.breakfast)}
													</label>
													<div
														class={styles.plusMinus}
														id={product.breakfast}
														onClick={(e) => this.updateStackValue((this.stringToInt(e.currentTarget.id) - 1), product.event_id, 'breakfast')}
													>
														-
													</div>
												</div>

												{/* Lunch */}
												<div class={styles.stack}>
													<div
														class={styles.plusMinus}
														id={product.lunch}
														onClick={(e) => this.updateStackValue((this.stringToInt(e.currentTarget.id) + 1), product.event_id, 'lunch')}
													>
														+
													</div>
													<label
														class={styles.stackValues}
														contentEditable
														id={product.product_name + " lunch " + product.lunch}
														tabindex="0"
														onKeyDown={(e) => this.keyDown(e, e.currentTarget.id)}
														onBlur={(e) => this.updateStackValue((this.stringToInt(e.currentTarget.textContent)), product.event_id, 'lunch', e.currentTarget.id)}
													>
														{this.addDecimal(product.lunch)}
													</label>
													<div
														class={styles.plusMinus}
														id={product.lunch}
														onClick={(e) => this.updateStackValue((this.stringToInt(e.currentTarget.id) - 1), product.event_id, 'lunch')}
													>
														-
													</div>
												</div>

												{/* Dinner */}
												<div class={styles.stack}>
													<div
														class={styles.plusMinus}
														id={product.dinner}
														onClick={(e) => this.updateStackValue((this.stringToInt(e.currentTarget.id) + 1), product.event_id, 'dinner')}
													>
														+
													</div>
													<label
														class={styles.stackValues}
														contentEditable
														id={product.product_name + " dinner " + product.dinner}
														tabindex="0"
														onKeyDown={(e) => this.keyDown(e, e.currentTarget.id)}
														onBlur={(e) => this.updateStackValue((this.stringToInt(e.currentTarget.textContent)), product.event_id, 'dinner', e.currentTarget.id)}
													>
														{this.addDecimal(product.dinner)}
													</label>
													<div
														class={styles.plusMinus}
														id={product.dinner}
														onClick={(e) => this.updateStackValue((this.stringToInt(e.currentTarget.id) - 1), product.event_id, 'dinner')}
													>
														-
													</div>
												</div>

												<div class={styles.type}>
													{this.removeParentheses(product.serving_type)}
												</div>
											</div>
										</div>

										{/* Total */}
										<div class={styles.cellTotal}>
											{this.calculateServings(product.breakfast, product.lunch, product.dinner, product.serving_size)}
										</div>

										<div class={styles.removeBtn}>
											<IconButton>
												<DeleteIcon onClick={() => this.removeProduct(product.event_id)} />
											</IconButton>
										</div>

									</div>
								)
							}) : null
							}
						</div>
						
						
					</div>

					{multipleUnits? <div class={styles.multipleUnits}>~ For any nutrients that show multiple amounts, they are coming from different products and do not have a conversion rate to a common unit. ~</div> : null}

					<div class={styles.leftSide}>
						<div>
							{/* Nutrient table on the left hand side of the home page,
							stored the nutrient table in its own component class*/}
							<NutrientTable agg={agg} ref={el => (this.componentRef = el)} />
						</div>
						{/* <div> 
						<List listAttributes={listAttributes}ref={el => (this.componentRef3 = el)} />
						</div> */}
						<br />
						<div>
							{/* Other Ingredient table on the left hand side of the home page,
							stored the nutrient table in its own component class*/}
							<OtherIngredientTable otherIngredients={otherIngredients}ref={el => (this.componentRef2 = el)} />
						</div>
					</div>
					<div>
						{/* RDA nutrient table with 27 RDA nutrients */}
						<div class={styles.rdaTable}ref={el => (this.componentRef4 = el)}>
							{/* Header information for nutrient table */}
							<div class={styles.nutrientHeader}>
								{/* Header above the nutrient table */}
								<div class={styles.titleAndCollapse}>
									<div class={styles.rda}>
										<em>{"Recommended Daily Allowances"}</em>
									</div>
									<div class={styles.collapse} onClick={() => this.setState({ rdaCollapsed: !rdaCollapsed })}>
										{this.state.rdaCollapsed ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
									</div>
								</div>
								<div class={styles.category}>
									{rdaCat}
								</div>
								<div>
									<div class={styles.rdaHeader}>
										{"Nutrient"}
									</div>
									<div class={styles.rdaHeader}>
										{"Your Total"}
									</div>
									<div class={styles.rdaHeader}>
										{"RDA"}
									</div>
								</div>
							</div>
							<div class={styles.rightScrollableDiv}>
								{/* Where RDA nutrients will be mapped */}
								{(!!rdaAgg && !rdaCollapsed) ? Object.keys(rdaAgg).map((item, ri) => {
									return (
										<div class={this.rowStyle(ri)}>
											{/* RDA Nutrient name */}
											<div class={styles.rdaName}>
												{item}{Array.isArray(rdaAgg[item].unit) ? rdaAgg[item].unit.length > 1 ? <div class={styles.note}>~ See Above ~</div> : null : null}
											</div>
											
											{/* Your Total */}
											<div class={styles.rdaColumns}>
												<div class={styles.nutrientParent}>
													<div class={styles.nutrientChild}>
														{Array.isArray(rdaAgg[item].similar) ? rdaAgg[item].similar.map(element => <div>{element}</div>) : null}
													</div>
													<div class={styles.nutrientChild}>
														{Array.isArray(rdaAgg[item].unit) ? rdaAgg[item].unit.map(element => <div>{element}</div>) : null}
													</div>
													
												</div>
											</div>
											{/* RDA */}
											<div class={styles.rdaColumns}>
												{`${rdaAgg[item].rda + " " + rdaAgg[item].unit[0]}`}
											</div>
										</div>
									)
								}) : null
								}
							</div>
						</div>

					</div>

					{!!e ?
						this.recordChoice(e)
						: null
					}

				</div>

				<div>
					<Snackbar
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						style={{ height: "60%" }}
						open={this.state.NANError}>
						<Alert onClose={handleNANClose} severity="error" sx={{ width: '100%' }}>
							Invalid stack input
						</Alert>
					</Snackbar>
					<Snackbar
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						style={{ height: "60%" }}
						open={this.state.InvalidNumberError}>
						<Alert onClose={handleInvalidNumberClose} severity="error" sx={{ width: '100%' }}>
							Stack values must be between 0 and 100
						</Alert>
					</Snackbar>
				</div>
			</div>
		)
	}

}

export default List;