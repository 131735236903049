import React from 'react';
import styles from './Visualize.module.css';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, ResponsiveContainer, ComposedChart, ReferenceLine
} from 'recharts';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
/* Converts old units to new units */
const convert=(numToConvert, nutrient)=>{
  let temp = 0
  if (nutrient.startsWith("Vitamin A")) {
    temp = numToConvert * 0.3
  }
  else if (nutrient.startsWith("Vitamin D")) {
    temp = numToConvert * 0.025
  }
  else if (nutrient.startsWith("Vitamin E")) {
    temp = numToConvert * 0.67
  }
  else if (nutrient.startsWith("Niacin")) {
    temp = numToConvert / 60.0
  }
  else if (nutrient.startsWith("Folate")) {
    temp = numToConvert * 2
  }
  else {
    if (numToConvert != null) {
      return numToConvert.toFixed(0)
    }
  }
  return (parseFloat('' + temp).toFixed(0))
}
const mg = [
  {
    name: 'Vitamin A', uv: 4000, pv: 2400,
  },
  {
    name: 'Vitamin B', uv: 3000, pv: 1398,
  },
  {
    name: 'Vitamin C', uv: 2000,
  },
  {
    name: 'Vitamin D', uv: 2780, pv: 3908,
  },
  {
    name: 'Vitamin E', uv: 1890, pv: 4800,
  },
  {
    name: 'Vitamin F', uv: 2390, pv: 3800,
  },
  {
    name: 'Vitamin G', uv: 3490, pv: 4300,
  },
  {
    name: 'Vitamin H', pv: 2300,
  },
];

const data01 = [
  {
    "name": "Group A",
    "value": 400
  },
  {
    "name": "Group B",
    "value": 300
  },
  {
    "name": "Group C",
    "value": 300
  },
  {
    "name": "Group D",
    "value": 200
  },
  {
    "name": "Group E",
    "value": 278
  },
  {
    "name": "Group F",
    "value": 189
  }
];
const data02 = [
  {
    "name": "Group A",
    "value": 2400
  },
  {
    "name": "Group B",
    "value": 4567
  },
  {
    "name": "Group C",
    "value": 1398
  },
  {
  	"name": "Group D",
    "value": 9800
  },
  {
    "name": "Group E",
    "value": 3908
  },
  {
    "name": "Group F",
    "value": 4800
  }
];

const pie = [data01, data02]
const pl = pie.length

const percentages = mg.map((nutrient, index) => {
	var temp = Object.assign({}, nutrient);
	const keys = Object.keys(nutrient)
	const values = Object.values(nutrient)
	values.map((a, i) => {
		//use suggestions
		if(i !== 0) {
			const val = Math.round((a /(Math.random()*5000.0)))
			temp[keys[i]] = val
		}
	})
	return temp
})
const CustomLabel = props => {
  console.log(props);
  // return (
  //   <g>
  //     <text x={props.viewBox.x} y={props.viewBox.y} fill="black" dy={20} dx={0}>
  //       100% RDA
  //     </text>
  //     <text x={props.viewBox.x} y={props.viewBox.y} fill="black" dy={525} dx={0}>
  //       100% RDA
  //     </text>
  //     <text x={props.viewBox.x} y={props.viewBox.y} fill="black" dy={1050} dx={0}>
  //       100% RDA
  //     </text>
  //   </g>
  // );
};
const options = ['Percentage of RDA']
const data = [mg, percentages]
const colors = ["#8884d8", "#82ca9d"]


class Visualize extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
    	selected: 0,
      events: null,
      aggregate: null,
      rdaAggregate: null
    };
  }

  getHistory() {
    axios.get(`/api/user/${this.props.username}/history`).then(res => { 
      if(res.status === 200) {
        this.setState({ events: res.data.status.events })
        this.setState({ aggregate: res.data.status.aggregated })
        this.setState({ rdaAggregate: res.data.status.rdaAggregated })
      }
    }).catch(err => {
      console.log('No events loaded')
    })
  }

  componentDidMount() {
    this.getHistory()
  }

	render() {
    const agg = this.state.aggregate
    const rdaAgg = this.state.rdaAggregate
    const all = this.state.events?.map(item => item.product_name)
    const bar = !!rdaAgg ? Object.keys(rdaAgg)?.map(item => {
      // const makeup = agg[item].makeup.map(({ name, amount }) => {
      //   return ({
      //     [name]: amount,
      //   })
      // }) for component bar graph
      return ({
        name: item,
        Percentage: convert((rdaAgg[item].similar / rdaAgg[item].rda) * 100,item)
      })
    }) : null

		const select = this.state.selected
		const barHeight = `${70*data[select].length}px`

		return(
      <div> 
        <div class={styles.visualize}>
				<div class={styles.select}>
			     <FormControl>
		        <InputLabel>Data Display</InputLabel>
			        <Select
			          value={select}
			          onChange={(e) => this.setState({ selected: e.target.value })}
			        >
		          {options.map((option,index) => {
		          	return <MenuItem value={index}>{option}</MenuItem>
		          })}
		        </Select>
		      </FormControl>
	      </div>
        <div class={styles.username}>
					Percentage of Recommended Daily Allowance for <em>{this.props.username}</em>
				</div>
					<div 
          class={styles.bar} 
          style={{ height: barHeight }}
          >
						<ResponsiveContainer width="100%" height="200%">
				      <ComposedChart
				        data={bar}
				        layout="vertical">
				        <CartesianGrid strokeDasharray="3 3" fill="white"/>
				        <XAxis type="number" />
				        <YAxis dataKey="name" type="category" width={200} />
				        <Tooltip />
				        <Legend />
                {/*all?.map((val, i) => { 
                  return <Bar dataKey={val} stackId="a" fill={colors[i%2]} />
                })*/}
                <Bar dataKey="Percentage" stackId="a" fill={colors[0]} />
                {/* <ReferenceLine x={100} label={CustomLabel} stroke="red"/> */}
				      </ComposedChart>
			     	</ResponsiveContainer>
			     </div>
			     <div class={styles.pie}>
						{/*<ResponsiveContainer width="100%" height="100%">
				      <PieChart>
				      	<Tooltip />
				      	{pie.map((data, i) => {
				      		const size = 200/pl
				      		const inner = i === 0 ? 0 : i*size+10
				      		const outer = (i+1)*size
				      		return (
				      			<Pie 
				      				data={data} 
				      				dataKey="value" 
				      				nameKey="name" 
				      				cx="50%" 
				      				cy="50%" 
				      				innerRadius={inner} 
				      				outerRadius={outer} 
				      				fill={colors[i]} 
				      				label={pl === i+1}
				      			/>
				      		)
				      	})}
				      </PieChart>
			     	</ResponsiveContainer>*/}
			     </div>    
			</div>
        
        
      </div>
			
		)
	}
}

export default Visualize;
