import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import styles from './Home.module.css';
import 'material-design-icons';
import List from './Pages/List/List';
import Totals from './Pages/Totals/Totals';
import Visualize from './Pages/Visualize/Visualize';
import Shop from './Pages/Shop/Shop';
import Account from './Pages/Account/Account';
import Add from './Pages/Add/Add';
import Help from './Pages/Help/Help';
import Admin from './Pages/Admin/Admin';
import axios from 'axios';
import { Button } from '@material-ui/core';
import Message from './Message'



export default function Home(props) {

	const [logout, setLogout] = useState(false)
	const [selected, setSelected] = useState('Home')
	const [accountMessage, setAccountMessage] = useState('uninitialized')
	const logoutAcc = () => {
		axios.get('/auth/logout')
			.then(response => true)
			.then(data => { setLogout(data) })
	}


	const propState = props.location.state
	let username
	let email
	let user_status
	if (propState) {
		username = propState.username
		email = propState.email
		user_status = propState.user_status
		if (accountMessage === 'uninitialized') {
			setAccountMessage(propState.account_message)
		}
		// document.cookie = `username=${username}; path=/home`
	}
	else {
		return <Redirect to='/' />
	}

	const handleButtonClick = () => {
		setAccountMessage('User Acknowledged')
		console.log('button clicked')
	}

	const types = {
		Home: {
			icon: 'featured_play_list',
			component: <List username={username} />
		},
		Inventory: {
			icon: 'description',
			component: <Totals username={username} />
		},
		// Add: {
		// 	icon: 'add_circle',
		// 	component: <Add username={username} />
		// },
		Visualize: {
			icon: 'bar_chart',
			component: <Visualize username={username} />
		},
		// Shop: {
		// 	icon: 'shopping_cart',
		// 	component: <Shop username={username}/>
		// },
		"Help": {
			icon: 'help_center',
			component: <Help username={username} email={email} />
		},
		Account: {
			icon: 'account_circle',
			component: <Account username={username} />
		},
		Admin: {
			icon: 'query_stats',
			component: <Admin />
		}
	}

	const re = {
		pathname: '/',
		state: { logout: logout }
	}

	let propAccountMessage = accountMessage
	return (
		<div class={styles.home}>
			<div class={styles.navbar}>
				{Object.keys(types).map((type, index) => {
					if ((type !== "Admin") || user_status === "admin" || user_status === "developer") {
						return (
							<div
								key={type}
								class={selected === type ? styles.navSelected : styles.navItem}
								onClick={() => setSelected(type)}
							>
								<i className="material-icons md-light">
									{types[type].icon}
								</i>
								<div class={styles.navText}>
									{type}
								</div>
							</div>
						)
					}
				})}
 
				<div class={styles.logout} onClick={() => logoutAcc()}>
					Log Out
				</div>

			</div>
			<div class={styles.content}>
				{types[selected].component}
				<div class={styles.footer}>
					<a href="/disclaimer">Terms Of Service |</a>
					<a href="/privacy">Privacy Policy |</a>
					<a href="/disclaim">Disclaimer |</a>
					<a href="/missionstatement"> Mission Statement |</a>
					 <a href="/vision"> Vision | </a>
					<a href="/contact"> Contact us |</a>
					<a href="https://www.youtube.com/watch?v=08Uk2-tJYwY"> User Tutorial Video </a>
				</div>
				<div class={styles.citation}>
					Products sourced from the National Institutes of Health, Office of Dietary Supplements. Dietary Supplement Label Database, 2022. https://dsld.od.nih.gov/.
				</div>
				<div class={styles.TM}> NutraData™ </div>
			</div>
			{logout ? <Redirect to={re} /> : null}
			{(accountMessage && accountMessage !== 'User Acknowledged') ? <Message messages={propAccountMessage} username={username} parentCallback={handleButtonClick} /> : null}

		</div>
	)
}