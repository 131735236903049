import React,  { Fragment, useState } from 'react';
import styles from './Searching.module.css';
import axios from 'axios';
import { TextField } from '@material-ui/core';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert'
import { Button } from '@material-ui/core';
import { display } from '@mui/system';

async function getProducts(input, type) {
	const body = {[type]: input}
	return axios.post(`/api/product/${type}`, body).then(res => {
  	return res.data.status
	}).catch(err => {
		console.log(err.response.statusText)
	})
}

async function getProduct(id) {
	return axios.get(`/api/product/${id}`).then(res => {
  	return res.data.status
	}).catch(err => {
		console.log(err.response.statusText)
	})
}

async function addProduct(id, username, amount, value) {
	let route
	let body
	if(!!amount) {
		body = { dsld_id: id, amount: amount}
		route = '/api/event' //This route is never used anymore for our purposes
	} else {
		body = { dsld_id: id }
		route = `/api/user/${username}/inventory`
	}
	
	return axios.post(route, body).then(res => {
		console.log("Status" +res.status);
		console.log("Value" + value);
  		if(res.status === 200) {
			  if (value === 1){
				console.log("IN HERE");
				const body = {
				  dsld_id: id,
				  username: username,
				  amount: 0,    //By default the amounts for all stacks will be zero, so we don't even bother passing it in
				}
				axios.post('/api/event', body).then(res => {
				  if (res.status === 200) {
				  }
				})
			  }
			  return 200;  
		}
	return 200;
	}).catch(err => {
		return err.response.status
	})

}

function Searching({ username, type, name, amount, value}) {
	const [products, setProducts] = useState([])
	const [search, setSearch] = useState('')
	const [selected, setSelected] = useState(null)
	const [visible, setVisible] = useState(false)

	const displayProducts = () => {
		getProducts(search, type).then(data => setProducts(data))
	} 

	const displayAddProduct = () => {
		return <div><div
		className={styles.add}
		onClick={() => addProduct(selected.dsld_id, username, amount, 0).then(result => showResultMessage(result))}>
		Add Product to Inventory
		</div>
		<div
		className={styles.add2}
		
		onClick={() => addProduct(selected.dsld_id, username, amount, 1).then(result => showResultMessage(result))}>
		Add Product to Inventory/Stack
		</div>
		</div>
		
	}

	const setProduct = (id) => {
		setSearch('')
		setProducts([])
		getProduct(id).then(data => setSelected(data))
	}

	const typing = (text) => {
		setSearch(text)
		setSelected(null)
	}

	//Snackbar setups
	const [successOpen, setSuccessOpen] = useState(false)
	const [alreadyAddedOpen, setAlreadyAddedOpen] = useState(false)
	const [errorAddingOpen, setErrorAddingOpen] = useState(false)
	const handleSuccessClose = () => {
		setSuccessOpen(false)
		return
	}
	const handleAlreadyAddedClose = () => {
		setAlreadyAddedOpen(false)
		return
	}
	const handleErrorAddingClose = () => {
		setErrorAddingOpen(false)
		return
	}
	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});

	const showResultMessage = (result) => {
		if (result == 409) {
			setAlreadyAddedOpen(true)
		}
		else if (result == 200) {
			setSuccessOpen(true)
		}
		else {
			setErrorAddingOpen(true)
		}
	}

	const textFieldLabel = (type) => {
		if (type == 'Product') {
			return 'Input partial or full product name'
		}
		return 'Input partial or full UPC code'
	}

	return (
		<div class={styles.searching}>
			<div class={styles.textFieldAndButton}>
				<div class={styles.input}>
					<TextField
						onChange={(e) => typing( e.target.value )}
						label={textFieldLabel(name)}
						width={1}
						fullWidth={true}
					/>
				</div>
				<div class={styles.button}>
					<Button variant="contained" onClick={() => displayProducts()}>
						Search for {name}
					</Button>
				</div>
			</div>
				{ !!products ?
				products.map(product => {
					return (
						<div className={styles.productParent}>
						<div class={styles.productResult} onMouseEnter={() => setVisible(!visible)} onClick={() => setProduct(product.dsld_id)}>
							{`${product.product_name} - `+(product.upc?`${product.upc}`:`No UPC`)+(product.product_message?(product.product_message.substr(0,8) === 'DISABLED'?` - Disabled`:``):``)} 
						</div>
						<div className={styles.productImage} onClick={() => {product.image_link? window.open(product.image_link): window.open("https://s3-us-gov-west-1.amazonaws.com/cg-355fa78b-864d-4a44-b287-0369f11f024a/pdf/"+product.dsld_id+".pdf") }}>View label</div>
						</div>
					)
				})
				: null
				}
				{ !!selected ?
					<div className={styles.selected}>
						{/* <img src={selected.product_image.toString()}/> */}
						<div>{`Name - ${selected.product_name}`}</div>
						<div>{`UPC - `+ (selected.upc?`${selected.upc}`:`No UPC`)}</div>
						{
						selected.product_message?(selected.product_message.substr(0,8) === 'DISABLED'?<div>{selected.product_message.substr(9)}</div>
						: displayAddProduct())
						: displayAddProduct()
						}
					</div>
					: null
				}
			<Snackbar
				anchorOrigin={{
					vertical: 'middle',
					horizontal: 'center',
				}}
				style={{ 
					width: "250px",
					textAlign: "center",
			 	}}
				open={alreadyAddedOpen}
				autoHideDuration={3000}>
				<Alert onClose={handleAlreadyAddedClose} severity="error" sx={{ width: '100%' }}>
					Product has already been added to your inventory
				</Alert>
			</Snackbar>
			<Snackbar
				anchorOrigin={{
					vertical: 'middle',
					horizontal: 'center',
				}}
				style={{ 
					width: "250px",
					textAlign: "center"
			 	}}
				open={errorAddingOpen}
				autoHideDuration={3000}>
				<Alert onClose={handleErrorAddingClose} severity="error" sx={{ width: '100%' }}>
					Error adding to inventory
				</Alert>
			</Snackbar>
			<Snackbar
				anchorOrigin={{
					vertical: 'middle',
					horizontal: 'center',
				}}
				style={{ 
					width: "250px",
					textAlign: "center",
			 	}}
				open={successOpen}
				autoHideDuration={3000}>
				<Alert onClose={handleSuccessClose} severity="success" sx={{ width: '100%' }}>
					Product successfully Added!	
				</Alert>
			</Snackbar>
		</div>
	)
}

export default Searching